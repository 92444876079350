import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql, Link } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CV from '../components/cv';
import { SizeMe } from 'react-sizeme';
import * as style from '../styles/navigation.module.css';
export const _frontmatter = {
  "title": "CV",
  "path": "/cv"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    <SEO />
    <Layout>
      <div style={{
      "display": "contents"
    }}>
        <span className={style.button} style={{
        "margin": "0 0 16px 0"
      }}>
          <a href="/Joe Monk CV.pdf" download="/Joe Monk CV.pdf">Download</a>
        </span>
      </div>
      <div style={{
      "width": "100%"
    }}>
        <SizeMe render={({
        size
      }) => {
        return <CV size={size} />;
      }} />
      </div>
    </Layout>
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      